import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika-konfidencijnosti"} />
		<Helmet>
			<title>
				KnowledgeBridge
			</title>
			<meta name={"description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:title"} content={"KnowledgeBridge"} />
			<meta property={"og:description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header12 />
		<Section>
			<Text font="--headline2">
				{"  "}Політика конфіденційності
			</Text>
			<Text font="--base">
				Ми в KnowledgeBridge з повагою ставимося до вашої конфіденційності. Ця політика пояснює, як ми збираємо, використовуємо, зберігаємо та захищаємо вашу особисту інформацію під час використання нашого вебсайту та сервісів.
			</Text>
			<Text font="--headline4">
				{"  "}Збір інформації
			</Text>
			<Text font="--base">
				Ми можемо збирати наступні типи даних:
				<br />
				<br />
				{"  "}- Ваше ім'я, контактну інформацію та електронну адресу.
				<br />
				{"  "}- Інформацію про ваші бронювання, оренди велосипедів та пов'язані послуги.
				<br />
				{"  "}- Дані про ваші уподобання для покращення якості наших сервісів.
			</Text>
			<Text font="--headline4">
				{"  "}Використання інформації
			</Text>
			<Text font="--base">
				Ми використовуємо ваші дані для:
				<br />
				<br />
				{"  "}- Обробки ваших запитів та замовлень.
				<br />
				{"  "}- Забезпечення якісного обслуговування клієнтів.
				<br />
				{"  "}- Надсилання інформаційних листів, акцій та оновлень.
			</Text>
			<Text font="--headline4">
				{"  "}Захист даних
			</Text>
			<Text font="--base">
				{"  "}Ми використовуємо сучасні технології шифрування та безпеки для захисту вашої інформації від несанкціонованого доступу, втрати чи розголошення.
			</Text>
			<Text font="--headline4">
				{"  "}Передача третім сторонам
			</Text>
			<Text font="--base">
				{"  "}Ми не передаємо вашу особисту інформацію третім сторонам, за винятком випадків, передбачених законом, або для забезпечення виконання наших послуг через перевірених партнерів.
			</Text>
			<Text font="--headline4">
				{"  "}Ваші права
			</Text>
			<Text font="--base">
				Ви маєте право:
				<br />
				<br />
				{"  "}- Отримувати доступ до ваших даних.
				<br />
				{"  "}- Вносити зміни або видаляти вашу особисту інформацію.
				<br />
				{"  "}- Відмовлятися від отримання рекламних листів.
			</Text>
			<Text font="--headline4">
				{"  "}Зміни в політиці
			</Text>
			<Text font="--base">
				{"  "}Ми залишаємо за собою право оновлювати цю політику в будь-який час. Всі зміни будуть публікуватися на цій сторінці.
			</Text>
			<Text font="--headline4">
				{"  "}Контакти
			</Text>
			<Text font="--base">
				Якщо у вас виникли питання щодо нашої політики конфіденційності, будь ласка, зв'яжіться з нами за адресою info@knowledgebridge24.live.com.
			</Text>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});